import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  OktaAuthStateService,
  OktaConfig,
  OKTA_CONFIG,
} from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { UserService } from 'app/core/user/user.service';
import { concat, from, interval, Observable, of } from 'rxjs';

import {
  AdminPermissionSet,
  AdminService,
  AdminUser,
  DevService,
} from '@admin-api/index';
import {
  ignoreElements,
  map,
  startWith,
  switchMap,
  takeWhile,
} from 'rxjs/operators';

declare let pendo: any;

@Injectable()
export class AuthService {
  private isLoadingUser = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _adminService: AdminService,
    private _devService: DevService,
    @Inject(OKTA_CONFIG) private _oktaConfig: OktaConfig,
    public _authStateService: OktaAuthStateService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  handleAuthStateObservable(authState: Observable<AuthState>) {
    authState.subscribe((authState) => {
      if (authState.isAuthenticated) {
        this.isLoadingUser = true;
        this._adminService
          .retrieveCurrentUser()
          .subscribe((user: AdminUser) => {
            // Store the user on the user service
            this._userService.user = user;
            this.configurePendo(user);
            this.isLoadingUser = false;
            // Return a new observable with the response
            //return of(response);
          });
      } else {
        this.reset();
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * If the permission is valid for this user then it will return true. Else false.
   * @param permission e.g. 'paysafe_secrets:read'
   * @returns
   */
  isPermissionAllowed(
    permission?: AdminPermissionSet.PermissionsEnum,
  ): Observable<boolean> {
    const source = this._userService.user$.pipe(
      map((user) => {
        if (permission == null || user == null || user.permissions == null) {
          return false;
        } else {
          return (
            user.permissions.includes('*') ||
            user.permissions.includes(permission)
          );
        }
      }),
    );

    // If this method is called *prior* to the response of /admin/me, then
    // it will inaccurately notify the UI that the user doesn't have permission
    // This code will wait for /admin/me to respond before proceeding.
    return concat(
      interval(100).pipe(
        startWith(0),
        // tap(() => console.log('Waiting for user to load')),
        takeWhile(() => this.isLoadingUser),
        ignoreElements(),
      ),
      source,
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: {
    email: string;
    password: string;
    rememberMe: boolean;
  }): Observable<any> {
    // Throw error, if the user is already logged in
    // if (this._authenticated) {
    //   return throwError('User is already logged in.');
    // }

    return this._devService
      .login({
        adminLoginParams: {
          email: credentials.email,
          password: credentials.password,
        },
      })
      .pipe(
        switchMap((response) => {
          // Store the user on the user service
          this._userService.user = response.admin_user;
          // Return a new observable with the response
          return of(response);
        }),
        // catchError((error) => {
        //     // if we fail to log in should we erase access tokens and users?
        //     return of(error);
        // }),
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this.reset();

    // The countdown page redirects immediately
    this._oktaConfig.oktaAuth.signOut();

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    return from(this._oktaConfig.oktaAuth.isAuthenticated());
  }

  private configurePendo(user: AdminUser) {
    pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: user.name,
      },
    });
  }

  private reset(): void {
    this._userService.user = null; // ???
  }
}
