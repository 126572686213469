import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'user-list-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserListImpersonateComponent implements OnInit {
  impersonationForm: UntypedFormGroup;

  constructor(
    public matDialogRef: MatDialogRef<UserListImpersonateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUserListImpersonateComponentData,
    private _formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.impersonationForm = this._formBuilder.group({
      reason: ['', [Validators.required]],
      beta_redirect: [false, [Validators.required]],
    });
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  proceed(): void {
    if (this.impersonationForm.invalid) {
      return;
    }

    this.matDialogRef.close({
      reason: this.impersonationForm.value['reason'],
      beta_redirect: this.impersonationForm.value['beta_redirect'],
    });
  }
}

export interface IUserListImpersonateComponentData {
  user_name?: string;
  user_email?: string;
  user_id?: string;
}
