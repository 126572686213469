import { AdminExtendedUser, AdminService, User } from '@admin-api/index';
import { VerifyUserResponse } from '@admin-api/model/verifyUserResponse';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { fuseAnimations } from '../../../../@fuse/animations';
import { environment } from '../../../../environments/environment';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
} from '../../../core/constants';
import { VerifyUserCheckDialogComponent } from '../verify-user-check-dialog/verify-user-check-dialog.component';
import { VerifyUserCreateDialogComponent } from '../verify-user-create-dialog/verify-user-create-dialog.component';
import {
  IUserListImpersonateComponentData,
  UserListImpersonateComponent,
} from './impersonate/impersonate.component';

@Component({
  selector: 'user-list',
  styleUrls: ['./user-list.component.scss'],
  templateUrl: './user-list.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) private _paginator: MatPaginator;
  @Input() accountId: string;

  public dataSource = new MatTableDataSource<AdminExtendedUser>();
  public verifyUserCreateDialogRef: MatDialogRef<VerifyUserCreateDialogComponent>;
  public verifyUserCheckDialogRef: MatDialogRef<VerifyUserCheckDialogComponent>;
  public usersCount: number = 0;
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;
  selectedUser: User | null = null;

  errorMessage: string = null;
  isLoading: boolean = true;

  usersTableColumns: string[] = [
    'type',
    'name',
    'account_name',
    'role',
    'last_login_at',
    'last_request_at',
    'verified',
    'actions',
  ];
  searchInputControl: UntypedFormControl = new UntypedFormControl();

  /**
   * Constructor
   */
  constructor(
    private _adminService: AdminService,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.dataSource.paginator = this._paginator;
    this.fetchFilteredUsers();

    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((q) => {
        this.fetchFilteredUsers();
      });
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this._paginator.page.subscribe(() => {
      this.fetchFilteredUsers();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  fetchFilteredUsers() {
    let offset = 0;
    let limit = DEFAULT_PAGE_SIZE;
    if (this._paginator) {
      offset = this._paginator.pageIndex * this._paginator.pageSize;
      limit = this._paginator.pageSize;
    }

    let q: string = this.searchInputControl.value ?? null;
    if (q && q.startsWith('acct_')) {
      this.accountId = q;
      q = null;
    }
    this.fetchUsers(
      q,
      this.accountId, // TODO: Account Selector
      offset,
      limit,
    );
  }

  detailsClicked(userId: string) {}

  fetchUsers(
    query?: string,
    accountId?: string,
    offset = 0,
    limit = DEFAULT_PAGE_SIZE,
  ) {
    this.isLoading = true;

    this._adminService
      .listAllUsers({ q: query, accountId, offset, limit })
      .subscribe(
        (res) => {
          this.dataSource.data = res.items;
          this.usersCount = res.total;
        },
        (error) => {
          this.isLoading = false;
          if (error?.error?.message) {
            this.errorMessage = 'Error: ' + error.error.message;
          } else if (error?.message) {
            this.errorMessage = 'Error: ' + error.message;
          } else {
            this.errorMessage = 'Unknown error';
          }
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  createUser() {}

  filterByTypeChanged(change: MatSelectChange): void {
    this.fetchFilteredUsers();
  }

  matMenuListItemsForUser(userId: string): MatMenuListItem[] {
    const listItems: MatMenuListItem[] = [];

    listItems.push({
      id: 'impersonate',
      text: 'Impersonate',
      icon: 'mat_outline:support_agent',
    });
    listItems.push({
      id: 'edit',
      text: 'Edit',
      icon: 'mat_outline:edit',
      disabled: true,
    });
    listItems.push({
      id: 'email',
      text: 'Email',
      icon: 'mat_outline:email',
      disabled: true,
    });

    return listItems;
  }

  clickMenuItem(item: MatMenuListItem, userId: string) {}

  impersonate(userId: string) {
    const user = this.dataSource.data.find((u) => u.id === userId);

    // Open the dialog
    const dialogRef = this._matDialog.open(UserListImpersonateComponent, {
      data: {
        user_name: user?.name,
        user_email: user?.email,
        user_id: userId,
      } as IUserListImpersonateComponentData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.reason) {
        this._adminService
          .impersonateUser({
            id: userId,
            userImpersonationParams: {
              reason: result.reason,
            },
          })
          .subscribe(
            (response) => {
              const url = result?.beta_redirect
                ? `${environment.hawkeye}/sign-in`
                : `${environment.iron}/auth/login`;
              (window as any).open(`${url}?token=${response.token}`, '_blank');
            },
            (error) => {
              // Failed to impersonate...
              // Pop-up error message
            },
          );
      }
    });
  }

  unlock(userId: string) {
    this._adminService.unlockUser({ id: userId }).subscribe(
      (response) => {
        this.fetchFilteredUsers();
      },
      (error) => {
        // Failed to impersonate...
        // Pop-up error message
      },
    );
  }

  verify(user: AdminExtendedUser) {
    if (user.verification_status !== VerifyUserResponse.StatusEnum.PENDING) {
      this.verifyCreate(user);
    } else {
      this.verifyCheck(user);
    }
  }

  verifyCreate(user: AdminExtendedUser) {
    this.verifyUserCreateDialogRef = this._matDialog.open(
      VerifyUserCreateDialogComponent,
      {
        panelClass: 'user-form-dialog',
        data: {
          user: user,
        },
      },
    );

    this.verifyUserCreateDialogRef.afterClosed().subscribe((res: any) => {
      if (!res) {
        return;
      }
      if (res.reason === 'recheck') {
        this.verifyCheck(user);
      } else {
        this.createVerification(res.getRawValue(), user);
      }
    });
  }

  verifyCheck(user: AdminExtendedUser) {
    this.verifyUserCheckDialogRef = this._matDialog.open(
      VerifyUserCheckDialogComponent,
      {
        panelClass: 'user-form-dialog',
        data: {
          user: user,
        },
      },
    );

    this.verifyUserCheckDialogRef.afterClosed().subscribe((res: any) => {
      if (!res) {
        return;
      }
      if (res.reason === 'resend') {
        this.createVerification(res.data, user);
      } else {
        this.checkVerification(res.getRawValue(), user);
      }
    });
  }

  createVerification(data: any, user: AdminExtendedUser) {
    this._adminService
      .createVerification({ verifyUserCreateParams: data, userId: user.id })
      .subscribe(
        (resp) => {
          this.fetchFilteredUsers();
          if (resp.status !== VerifyUserResponse.StatusEnum.PENDING) {
            this.openSnackBar(
              'Something went wrong sending verification code',
              'OK',
              true,
            );
          } else {
            this.openSnackBar(
              'Verification code sent for ' + resp.user_contact,
              'OK',
              false,
            );
            this.verifyCheck(user);
          }
        },
        (error) => {
          let errorMsg;

          if (error?.error?.message) {
            errorMsg = 'Error: ' + error.error.message;
          } else if (error?.message) {
            errorMsg = 'Error: ' + error.message;
          } else {
            errorMsg = 'Unknown Error';
          }
          this.openSnackBar(errorMsg, 'OK', true);
        },
      );
  }

  checkVerification(data: any, user: AdminExtendedUser) {
    this._adminService
      .checkVerification({ userId: user.id, verifyUserCheckParams: data })
      .subscribe(
        (resp) => {
          this.fetchFilteredUsers();
          switch (resp.status) {
            case VerifyUserResponse.StatusEnum.SUCCESSFUL: {
              this.openSnackBar(
                'Verification of ' + resp.user_contact + ' Successful',
                'OK',
                false,
              );
              break;
            }
            case VerifyUserResponse.StatusEnum.FAILED: {
              this.openSnackBar(
                'Verification of ' + resp.user_contact + ' Failed',
                'OK',
                true,
              );
              break;
            }
            case VerifyUserResponse.StatusEnum.PENDING:
            default: {
              this.openSnackBar(
                'Something went wrong verifying code of ' + resp.user_contact,
                'OK',
                true,
              );
              break;
            }
          }
        },
        (error) => {
          let errorMsg;

          if (error?.error?.message) {
            errorMsg = 'Error: ' + error.error.message;
          } else if (error?.message) {
            errorMsg = 'Error: ' + error.message;
          } else {
            errorMsg = 'Unknown Error';
          }
          this.openSnackBar(errorMsg, 'OK', true);
        },
      );
  }

  openSnackBar(message: string, action: string, warn: boolean): void {
    const snackTheme = warn ? 'mat-warn' : 'mat-primary';
    this._snackBar.open(message, action, {
      duration: 10000,
      verticalPosition: 'top',
      panelClass: ['mat-toolbar', snackTheme],
    });
  }

  verifyStatusTooltip(status: VerifyUserResponse.StatusEnum): string {
    let tooltip = '';

    switch (status) {
      case VerifyUserResponse.StatusEnum.PENDING:
        tooltip = 'Waiting on verification code';
        break;
      case VerifyUserResponse.StatusEnum.FAILED:
        tooltip = 'Failed user verification';
        break;
      case VerifyUserResponse.StatusEnum.SUCCESSFUL:
        tooltip = 'User is successfully verified';
        break;
      default:
        break;
    }

    return tooltip;
  }
}

export class MatMenuListItem {
  id: string;
  text: string;
  icon: string;
  disabled?: boolean = false;
}
