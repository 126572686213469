import packageJson from '../../package.json';

export const environment = {
  env: 'staging',
  api: 'https://staging-api.tilled.com',
  iron: 'https://staging-app.tilled.com',
  hawkeye: 'https://beta-staging-app.tilled.com',
  sentryDSN:
    'https://4b2d9b3fd64b4f258ffa338557deb906@o377738.ingest.sentry.io/5865083',
  production: true,
  hmr: false,
  // Same okta values for all environments
  okta: {
    issuer: 'https://tilled.okta.com/oauth2/default',
    client_id: '0oa3ecwce9EbZKTEy5d7',
  },
  version: packageJson.version,
};
